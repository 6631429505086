import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Homepage from './pages/homepage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Locations from './pages/locations';
import Contact from './pages/contact';
import Apply from './pages/apply.js'
import ReturnProcess from './pages/returnProcess';
import AcceptedContainers from './pages/acceptedContainers';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/locations' element={<Locations />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/apply' element={<Apply />} />
        <Route path='/returnprocess' element={<ReturnProcess />} />
        <Route path='/acceptedcontainers' element={<AcceptedContainers />} />
      </Routes>
      
    </BrowserRouter>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
