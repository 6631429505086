import React from "react";
import background from '../images/ApplyBackground.png'
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import backgroundSm from '../images/BackgroundSm.png'

function SubpageTitle(props) {
    const backgroundStyles = {
        backgroundColor: "rgba(black, 0.4)"
    }
    return(
        <div>
            <div className='d-none d-md-block'><Navbar /></div>

            <div className="d-block d-md-none"><Sidebar /></div>
            <div id="titleCard" style={backgroundStyles}>
                <img src={background} className="d-none d-md-block h-50"/>
                <img src={backgroundSm} className="d-block d-md-none"></img>
                <h3 id='title' className="position-absolute text-center fs-1 w-100">{props.title}</h3>
            </div>
            <p className="text-center pt-5 mx-sm-5">{props.subtitle}</p>
        </div>
    )
}

export default SubpageTitle