import React from 'react';
import '../App.css';
import background from '../images/HomeBackground.png'
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import Sidebar from '../components/sidebar';
import backgroundSm from '../images/BackgroundSm.png'
import SubpageTitle from '../components/subpageTitle';
import { Link } from 'react-router-dom';

function Homepage() {
    return(
        <div>
            <div className='d-none d-md-block'><Navbar /></div>
            <div className="d-block d-md-none"><Sidebar /></div>
            <div className='container-fluid text-center p-0 h-100 w-100'>
                <div className='position-relative'>
                    <img src={background} className="img-fluid d-none d-md-block"/>
                    <img src={backgroundSm} className="img-fluid d-block d-md-none"></img>
                    <div id="title-block">
                        <h1 id="title" style={{fontSize: '10vh'}} className="position-absolute">Bottle Depot</h1>
                        <h3 id="subtitle" className="position-absolute fs-3">One Stop to Return all NY Cans & Bottles</h3>  
                    </div>
                    
                </div>
                <div className="pt-3 pb-4 d-flex flex-column justify-content-center align-content-center row" style={{backgroundColor: '#F1F1F1'}}>
                    <h2 className='text-center mt-5 col'>What We Do</h2>
                    <p className='text-center align-self-center w-75 py-4 px-5 col '>We are the leading bottle and can return center in the Hudson Valley. We take all NY 5¢ deposit
                    empties and get your money back to you. There is no longer a need to wait on slow return machines. With returning your bottles and cans, you are
                    doing your part in protecting our environment. Come to a Bottle Depot near you for a fast, clean, and convenient bottle return experience.</p>
                </div> 
                <div className="d-flex w-75 mx-auto pt-3 pb-4 row" style={{backgroundColor: 'white'}}>
                    <h2 className='my-md-5 py-md-5 col-md-6'>Precounts</h2>
                    <p className='text-center mt-md-4 py-md-5 col-md-6'>To make your wait even shorter, we have introduced precounting.
                    You are free to count the returns you bring in beforehand. Please let us know if you have any precounts when you come in.
                    We reserve the right to count any one of your bags for verfication. Please make sure that your bags are labelled with the number of empties on each bag.</p>
                </div> 
                <div className="mw-100 mx-auto d-flex pt-3 pb-4 row justify-content-center text-center" style={{backgroundColor: '#F1F1F1'}}>
                    <p className='flex-lg-shrink-1 mt-5 pb-5 col-md-4 col-sm-12 order-2 order-md-1'>Not all containers are accepted. View a summary of what we accept here.</p>
                    <div className='pt-5 col-md-5 col-sm-12 order-1 order-md-2'>
                        <h2 className=''>Accepted Containers</h2>
                    </div>
                    
                </div> 
                <div className="d-flex w-75 mx-auto pt-3 pb-4 row" style={{backgroundColor: 'white'}}>
                    <h2 className='my-md-5 py-md-5 col-md-6'>Contact Us</h2>
                    <div className='mt-md-4 py-md-5 col-md-6'>
                        <p>Need some help? Get in Touch! </p>
                        <button className='btn btn-primary'><Link className="text-white text-decoration-none" to='/contact'>Contact Us</Link></button>
                        
                    </div>
                    
                </div> 
            </div>
            
            <Footer />
        </div>

        
        
    )
}


export default Homepage