import React from "react";
import "../App.css"
import Logo from "../images/BDLogo.png"
import { Link } from "react-router-dom";
import FBLogo from "../images/facebook.svg"
import InstaLogo from "../images/instagram.svg"
import TwitterLogo from "../images/twitter.svg"

export default function Sidebar() {
    return (
        <div>
            <a style={{zIndex: 100}} class="d-md-none d-block position-absolute w-25" data-bs-toggle="offcanvas" href="#offcanvas" role="button" aria-controls="offcanvas"><img className="" src={Logo}></img></a>
            <div style={{zIndex: 200}} className='d-block d-md-none'>
                <div className='offcanvas offcanvas-start' id="offcanvas" aria-labelledby='offcanvasLabel'>
                    <div class='offcanvas-header'>
                        <Link className="w-25" to="/" ><img src={Logo} className=""></img></Link>
                        <button type="button" class="btn-close text-reset me-3" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                    <Link className="nav-link active" to="/locations">Our Locations</Link>
                    <Link className="nav-link active mt-4" to="/contact">Contact</Link>
                    <Link className="nav-link active mt-4" to="/apply">Apply</Link>
                    <div className='dropdown p-0 mt-4'>
                        <button className='p-0 btn btn-link dropdown-toggle text-decoration-none text-dark' type="button" data-bs-toggle="dropdown" id="learnMoreDropdown">Learn More</button>
                        <ul className='dropdown-menu' aria-labelledby='learnMoreDropdown'>
                        <li><Link className='dropdown-item' to="/acceptedcontainers">Accepted Containers</Link></li>
                        <li><a href="#" className="dropdown-item">Return Process</a></li>
                        </ul>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    )
}