import React from "react";
import { GoogleMap, useLoadScript, MarkerF, InfoWindow } from "@react-google-maps/api";
import { useMediaQuery } from "react-responsive";



const mapContainerStyle = {
    width: "45vw",
    height: "45vw"
}
const mapContainerStyleSm = {
    width: "100vw",
    height: "100vw",
}

const center = {
    lat: 41.485139400896664,
    lng: -74.09714738407764
}

const options = {
    disableDefaultUI: true,
    zoomControl: true
}

export default function Map(props) {

    const {center = {lat: 41.485139400896664, lng: -74.09714738407764}} = props

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: "AIzaSyBDUDrlDXgPkovFLE9sJC2ABq5HA-Twysw"
    })

    function handleChange(event) {
        props.onChange(event)
    }
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    if (loadError) return "Error loading maps"
    if (!isLoaded) return "Loading Maps"

    return <div>
        
        <GoogleMap mapContainerStyle={isMobile ? mapContainerStyleSm : mapContainerStyle} zoom={8} center={props.selected ? {lat: props.selected.lat, lng: props.selected.lon} : center} options={options}>
        {props.markers}
        {props.selected ? (<InfoWindow position={{lat: props.selected.lat, lng: props.selected.lon}} onCloseClick={() => handleChange(null)}>
            <div>
                <h6>Bottle Depot - {props.selected.name}</h6>
                <p>{props.selected.streetAddress}, {props.selected.city}, {props.selected.state} {props.selected.zipCode}</p>
                <p>10:00 AM - 6:30 PM<br/>Sunday - Saturday</p>
                <p><a target="_blank" href={props.selected.link}>Directions</a></p>
            </div>
        </InfoWindow>) : null}
        </GoogleMap>
    </div>
}
