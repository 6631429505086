import React from "react";
import Footer from "../components/footer";
import SubpageTitle from "../components/subpageTitle";

function ReturnProcess() {
    return (
        <div>
            <SubpageTitle title="Return Process" subtitle="Returning your empty bottles and cans is easier than ever with Bottle Depot! In a simple three-step process, you can easily get your money back."/>
            <div className="mx-3">
                <h5 className="mt-5">Step One: Collect Your Bottles</h5>
                <p>There are no limits to the number of empties you can return and the containers do not need to be sorted.</p>
                <h5>Step Two: Bring Your Bottles to a Bottle Depot Location</h5>
                <p>Come in, drop your empties on the counters, and wait for one of our employees to count your total. No appointments needed.</p>
                <h5>Step Three: Collect Your Cash</h5>
                <p>As soon as your empties are counted, you will be handed your deposit in cash!</p>

                <h4>Precounting</h4>
                <h6>You can count your empties at home to save time at the store! Requirements for precounting include:</h6>
                <p>Empties must be in a clear plastic bag</p>
                <p>Each bag must be labeled with the number of bottles inside</p>
                <p>All empties in the bags must have a New York State deposit</p>
                <p className="mt-5">Note: Bottle Depot reserves the right to verify the number of precounted bottles by taking sample from the returned empties.</p>
            </div>

            <Footer />
        </div>
    )
}

export default ReturnProcess