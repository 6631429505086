import React from "react";
import Footer from "../components/footer";
import FacebookLogo from "../images/FacebookLogo.png"
import IndeedLogo from "../images/IndeedLogo.png"
import SubpageTitle from "../components/subpageTitle";
import "../App.css"

function Apply() {
    return( 
        <div>
            <div id="applyTitle">
                {/* <img src={background} className="h-50"/>
                <h3 id='title' className="position-absolute text-center w-100" style={styles}>Apply Now</h3> */}
                <SubpageTitle title="Apply Now" subtitle="We periodically add jobs to Indeed and Facebook Jobs. We will get back to you as soon as we can once an application is received." />
                {/* <h5 className="position-absolute text-center w-100"></h5> */}
            </div>
            <div className="d-flex container-fluid flex-sm-column align-items-center justify-content-sm-center">
                <div className="row">
                    <div className="col-md-5 py-5 text-center">
                        <a href="https://www.indeed.com/cmp/Bottle-Depot/jobs?jk=4aa104a4c3745067&start=0&clearPrefilter=1" rel="noreferrer" target="_blank">
                            <img alt="Facebook Logo" id="FacebookLogo" className="" src={FacebookLogo}></img>
                        </a>
                    </div>
                    <hr className="d-md-none d-sm-block"/>
                    <div className="col-md-1"></div>
                    <div className="col-md-6 align-self-center text-center">
                        <a href="https://www.facebook.com/nybottledepot1/jobs/">
                            <img alt="Indeed Logo" id="IndeedLogo" className="" src={IndeedLogo}></img>
                        </a>
                    </div>
                </div>
                
            </div>
                
            <Footer />
        </div>
    )
}
export default Apply