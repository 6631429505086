import React from "react";
import '../App.css';


function Footer() {
    var year = (new Date()).getFullYear().toString()
    return (
        <div class="d-flex ps-4 pt-4 pb-3 bottom-0" id="footer" style={{backgroundColor: '#003f5a', color: 'white'}}>
            <h6 className='pe-2'>© {year} Bottle Depot</h6>
            <div className="vr"></div>
            <div className='ps-2'><h6>Site Created By Tejpaul Singh</h6></div>
        </div>
    )
}

export default Footer