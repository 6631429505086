import React from 'react';
import '../App.css';
import { Link } from "react-router-dom"
import Logo from '../images/BDLogo.png'
import FBLogo from "../images/facebook.svg"
import InstaLogo from "../images/instagram.svg"
import TwitterLogo from "../images/twitter.svg"

function Navbar() {
  return (
    <main>
      <nav className="navbar navbar-fixed-top sticky justify-content navbar-custom">
       <Link to='/'><img src={Logo} className="w-25"></img></Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
  </button>
        <div className="justify-content-end navbar-text">
          <ul className="nav pr-2">
            <li className="nav-item justify-content-end">
              <Link className="nav-link active link-light" to="/locations">Our Locations</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active link-light" to="/contact">Contact</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active link-light" to="/apply">Apply</Link>
            </li>
            <li className="nav-item dropdown">
              <div className='dropdown'>
                <button className='btn btn-link dropdown-toggle text-decoration-none text-white' type="button" data-bs-toggle="dropdown" id="learnMoreDropdown">Learn More</button>
                <ul className='dropdown-menu' aria-labelledby='learnMoreDropdown'>
                  <li><Link className='dropdown-item' to="/acceptedcontainers">Accepted Containers</Link></li>
                  <li><Link className='dropdown-item' to="/returnprocess">Return Process</Link></li>
                </ul>
              </div> 
            </li>
            
              
        
          <li className='nav-item mx-2'><a target="_blank" className="align-middle" href="https://www.facebook.com/nybottledepot1"><img className="align-middle" src={FBLogo} ></img></a></li>
          <li className='nav-item mx-2'><a target="_blank" className="align-middle" href="https://www.instagram.com/nybottledepot/"><img className="align-middle" src={InstaLogo} ></img></a></li>
          <li className='nav-item mx-2'><a target="_blank" className="align-middle" href="https://twitter.com/nybottledepot"><img className="align-middle" src={TwitterLogo} ></img></a></li>

          </ul>
          
        </div>
      </nav>
    </main>
  );
}

export default Navbar;