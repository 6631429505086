import React from "react";
import SubpageTitle from "../components/subpageTitle";
import phoneLogo from "../images/phone.svg"
import emailLogo from "../images/email.svg"
import FBMessengerLogo from "../images/FBMessenger.png"
import Footer from "../components/footer"
import "../App.css"


function Contact() {

    const styles = {
        "contactInfo": {
        }
    }
    

    return(
        <div>
            <SubpageTitle title="Contact Us"/>
            <div className="mx-auto text-center d-flex justify-content-center row container-fluid mb-5 w-75">
                <div className="col-12 col-md-6 align-items-center">
                    <div className="w-md-75 w-100" id='contactInfo' style={styles.contactInfo}>
                        <div className="mx-auto">
                            <img className="mt-2" height="36" width="36" src={phoneLogo}/>
                            <h3 className="text-center mt-3">Give Us a Call:</h3>
                            <h4 className="text-center mt-3 mb-5">1-877-685-2496</h4>
                        </div>
                        <hr></hr>
                        <div className="mx-auto">
                            <img className="mt-4" height="36" width="36" src={emailLogo}/>
                            <h3 className="text-center mt-3">Send Us an Email:</h3>
                            <h4 className="text-center text-break mt-3">nybottledepot@nybottledepot.com</h4> 
                        </div> 
                    </div>
                </div>
                <hr className="d-md-none mt-5 mb-5"></hr>
                <div className="col-12 col-md-6 d-flex align-items-center">
                    <div className="mx-auto text-center">
                        <a target="_blank" href="http://fb.me/msg/nybottledepot1"><img className="w-25 mx-auto" src={FBMessengerLogo}></img></a>
                        <h5 className="mt-5">You can also message us directly on Facebook Messenger</h5>
                    </div>
                    
                </div>
            </div>
            <Footer /> 
        </div>
    )
}

export default Contact