import React from "react";
import SubpageTitle from "../components/subpageTitle";
import data from "../store_data"
import Map from "../components/Map";
import '../App.css'
import { MarkerF } from "@react-google-maps/api";
import { useMediaQuery } from "react-responsive";
import Footer from "../components/footer"


function Locations() {
    const [selected, setSelected] = React.useState(null)

    function handleChange(newValue) {
        setSelected(newValue)
    }

    const listHeight = {
        maxHeight: '45vw'
    }

    const listHeightSm = {
        maxHeight: '45vh'
    }

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return (
        <div>
            <SubpageTitle title="Our Locations"/>
            <div className="d-flex container-fluid justify-content-center">
                <div className="row w-sm-75">
                    <div className="p-0 order-md-0 order-1 col-12 col-md-4">
                        <ul id="locations-list" style={isMobile ? listHeightSm : listHeight} className='h-100 border rounded-left p-0'>
                            {data.map((store, index) => {
                                return (
                                    <li key={store.storeNum} className="border w-100 px-2 m-0 pt-2 pb-1" onClick={() => setSelected(store)}>
                                        <h5>{store.storeNum}. {store.name}</h5>
                                        <p>{store.streetAddress}<br/>{store.city}, {store.state} {store.zipCode}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="p-0 m-0 order-md-1 order-0 col-12 col-md-8">
                        <Map selected={selected}
                        markers={data.map(store => {return <MarkerF key={store.storeNum} position={{lat: store.lat, lng: store.lon}} onClick={() => {setSelected(store)}}></MarkerF>})}
                        onChange={() => handleChange(null)}/>
                    </div>
                </div>
                
                
            </div>
            <Footer />
        </div>
        
    )
}



export default Locations