const data = [
    {
        "name": "New Windsor",
        "storeNum": 1, 
        "streetAddress": "1079 Little Britain Road",
        "city": "New Windsor",
        "state": "NY",
        "zipCode": "12553",
        "lat": 41.485139400896664,
        "lon": -74.09714738407764,
        "link": "https://maps.google.com/?cid=10130725851764963300"
    },
    {
        "name": "Montgomery",
        "storeNum": 2, 
        "streetAddress": "1019 NY-17K",
        "city": "Montgomery",
        "state": "NY",
        "zipCode": "12549",
        "lat": 41.52570536702861,
        "lon": -74.19924761474087,
        "link": "https://maps.google.com/?cid=8888609022734966663"
    },
    {
        "name": "Walden",
        "storeNum": 3, 
        "streetAddress": "123 East Main Street",
        "city": "Walden",
        "state": "NY",
        "zipCode": "12586",
        "lat": 41.56272767118559,
        "lon": -74.1803839993802,
        "link": "https://maps.google.com/?cid=3839263616764602212"
    },
    {
        "name": "Middletown (211)",
        "storeNum": 4, 
        "streetAddress": "299 NY-211 E",
        "city": "Middletown",
        "state": "NY",
        "zipCode": "10940",
        "lat": 41.4555252464957,
        "lon": -74.399115638067,
        "link": "https://maps.google.com/?cid=11243553785149938241"
    },
    {
        "name": "Middletown (17M)",
        "storeNum": 5, 
        "streetAddress": "679 NY-17M",
        "city": "Middletown",
        "state": "NY",
        "zipCode": "10940",
        "lat": 41.48067609379597, 
        "lon": -74.41691198407987,
        "link": "https://maps.google.com/?cid=7689510253250687378"
    },
    {
        "name": "Kingston",
        "storeNum": 6, 
        "streetAddress": "440 Albany Ave",
        "city": "Kingston",
        "state": "NY",
        "zipCode": "12553",
        "lat": 41.94170465429214, 
        "lon": -74.00052766055512,
        "link": "https://maps.google.com/?cid=9862499444201836388"
    },
    {
        "name": "Goshen",
        "storeNum": 7, 
        "streetAddress": "2653 NY-17M",
        "city": "Goshen",
        "state": "NY",
        "zipCode": "10924",
        "lat": 41.3981089082209, 
        "lon": -74.36673342275412,
        "link": "https://maps.google.com/?cid=11658796324840555384"
    },
    {
        "name": "Port Ewen",
        "storeNum": 8, 
        "streetAddress": "441 Route 9W",
        "city": "Port Ewen",
        "state": "NY",
        "zipCode": "12466",
        "lat": 41.886750802542934, 
        "lon": -73.97513815589659,
        "link": "https://maps.google.com/?cid=5164844504978437976"
    },
    {
        "name": "Rosendale",
        "storeNum": 9, 
        "streetAddress": "1132 NY-32",
        "city": "Rosendale",
        "state": "NY",
        "zipCode": "12472",
        "lat": 41.847858646333414,
        "lon": -74.07511845265404,
        "link": "https://maps.google.com/?cid=5264434977300866511"
    },
    {
        "name": "Monticello",
        "storeNum": 10, 
        "streetAddress": "309 E Broadway",
        "city": "Monticello",
        "state": "NY",
        "zipCode": "12701",
        "lat": 41.65275812864257,
        "lon": -74.67792853796696,
        "link": "https://maps.google.com/?cid=8648214014666661401"
    },
    {
        "name": "Central Valley",
        "storeNum": 11, 
        "streetAddress": "228 NY-32",
        "city": "Central Valley",
        "state": "NY",
        "zipCode": "10917",
        "lat": 41.32897412725044, 
        "lon": -74.12093256142131,
        "link": "https://maps.google.com/?cid=7326107740118177163"
    },
    {
        "name": "Newburgh",
        "storeNum": 12, 
        "streetAddress": "99 NY-17K",
        "city": "Newburgh",
        "state": "NY",
        "zipCode": "12550",
        "lat": 41.5063054493633,
        "lon": -74.07494923009267,
        "link": "https://maps.google.com/?cid=6179074200783539307"
    },
    {
        "name": "Middletown (Highland Ave)",
        "storeNum": 13, 
        "streetAddress": "269 Highland Ave Ext",
        "city": "Middletown",
        "state": "NY",
        "zipCode": "10940",
        "lat": 41.463233448659075,
        "lon": -74.4119283613537,
        "link": "https://maps.google.com/?cid=10936565757463201190"
    },
    {
        "name": "Suffern",
        "storeNum": 14, 
        "streetAddress": "14 Lafayette Avenue",
        "city": "Suffern",
        "state": "NY",
        "zipCode": "10901",
        "lat": 41.117074223705096, 
        "lon": -74.15392820755356,
        "link": "https://maps.google.com/?cid=14412395788374252577"
    },
    {
        "name": "Nanuet",
        "storeNum": 15, 
        "streetAddress": "110 Demarest Mill Road",
        "city": "Nanuet",
        "state": "NY",
        "zipCode": "10954",
        "lat": 41.10131021334787,
        "lon": -73.99858259385586,
        "link": "https://maps.google.com/?cid=2523333517475964069"
    },
    {
        "name": "Orangeburg",
        "storeNum": 16, 
        "streetAddress": "319 NY-303",
        "city": "Orangeburg",
        "state": "NY",
        "zipCode": "10962",
        "lat": 41.04607203523602,
        "lon": -73.94484577123842,
        "link": "https://maps.google.com/?cid=535202331638700159"
    },
    {
        "name": "Stony Point",
        "storeNum": 17, 
        "streetAddress": "73 S Liberty Drive",
        "city": "Stony Point",
        "state": "NY",
        "zipCode": "10980",
        "lat": 41.23292270123166, 
        "lon": -73.98534880805127,
        "link": "https://maps.google.com/?cid=6735920677292718034"
    },
    // {
    //     "name": "West Haverstraw",
    //     "storeNum": 18, 
    //     "streetAddress": "31 S Route 9W",
    //     "city": "West Haverstraw",
    //     "state": "NY",
    //     "zipCode": "10993",
    //     "lat": 41.2085439731226,
    //     "lon": -73.98509740011457,
    //     "link": "https://maps.google.com/?cid=6294731349788513336"
    // },
    {
        "name": "Latham",
        "storeNum": 19, 
        "streetAddress": "462 Troy Schenectady Road",
        "city": "Latham",
        "state": "NY",
        "zipCode": "12110",
        "lat": 42.74685659798278,
        "lon": -73.75793206864492,
        "link": "https://maps.google.com/?cid=3705438214045608088"
    },
    {
        "name": "Troy",
        "storeNum": 20, 
        "streetAddress": "2829 6th Avenue",
        "city": "Troy",
        "state": "NY",
        "zipCode": "12180",
        "lat": 42.746123371779156, 
        "lon": -73.68205586069642,
        "link": "https://maps.google.com/?cid=9905453810381097805"
    },
    {
        "name": "Bethpage",
        "storeNum": 21, 
        "streetAddress": "4236 Hicksville Road",
        "city": "Bethpage",
        "state": "NY",
        "zipCode": "11714",
        "lat": 40.717009543023195,
        "lon": -73.48372437706921,
        "link": "https://maps.google.com/?cid=8170275787254953642"
    }

]

export default data
