import React from "react";
import SubpageTitle from "../components/subpageTitle";
import Footer from "../components/footer";
import AcceptedContainersDiagram from "../images/BDImages/Group 2.svg"
import '../App.css'

export default function AcceptedContainers() {
    return (
        <div>
            <SubpageTitle title="Accepted Containers" subtitle="There are certain containers that are not covered under the NY Bottle Bill. See below the types of containers that we take and those that we don't."/>
            <div className='d-flex container mx-auto pb-5 px-0'>
                <img className="mx-auto px-md-5 w-75" src={AcceptedContainersDiagram}></img>
            </div>
            <Footer />
        </div>
    )
}